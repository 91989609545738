<template>
  <q-layout view="hHh LpR fff" class="bg-info">
    <LoginHeader />

    <q-page-container>
      <router-view />
    </q-page-container>

    <Footer />
  </q-layout>
</template>

<script>
import LoginHeader from '../components/LoginHeader.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'LoginLayout',

  components: {
    LoginHeader,
    Footer
  }
};
</script>
