<template>
  <q-header class="bg-grey-10 text-white print-hide">
    <q-toolbar style="height: 80px">
      <q-btn dense flat round icon="menu" @click="ToggleLeftDrawer" />
      <q-toolbar-title class="lato">SEA In-House Tools</q-toolbar-title>
      <southeastern-logo></southeastern-logo>
    </q-toolbar>
  </q-header>

  <q-drawer :width="270" show-if-above v-model="leftDrawerOpen" class="print-hide" bordered side="left" bbehavior="desktop">
    <q-list>
      <q-item-label header class="text-subtitle1 text-white bg-primary" style="padding: 18px 0 0 18px; height: 55px">
        System Features
      </q-item-label>

      <q-item class="q-mt-md">Use this website for:</q-item>
      <SystemFeatures v-for="link in linksList" :key="link.title" v-bind="link" />
      <q-item class="q-mt-sm">...and more</q-item>
    </q-list>
  </q-drawer>
</template>

<script>
import store from "@/store";
import SystemFeatures from "./LoginMenu.vue";
import SoutheasternLogo from "./SoutheasternLogo.vue";
import { inject } from "vue";

const linksList = [
  {
    title: "Work Traveler",
    icon: "mdi-clipboard-list-outline"
  },
  { title: "Custom Glass XML", icon: "mdi-glass-pint-outline" }
];

export default {
  name: "Header",

  components: { SystemFeatures, SoutheasternLogo },

  setup() {
    //const leftDrawerOpen = ref(store.state.Depot.LeftDrawer);
    const leftDrawerOpen = inject("leftDrawerOpen");

    return {
      leftDrawerOpen,
      linksList,

      ToggleLeftDrawer() {
        leftDrawerOpen.value = !leftDrawerOpen.value;
        store.dispatch("Depot/LeftDrawer", leftDrawerOpen.value);
      }
    };
  }
};
</script>
